<template>
  <div class="document-card">
    <div class="general section">
      <div class="card-block">
        <div class="card-item">
          <p class="name">Номер</p>
          <p>{{ document.номер }}</p>
        </div>
        <div class="card-item" v-if="document.сесія">
          <p class="name">Прийнято на</p>
          <p>{{ document.сесія }} сесії міської ради</p>
        </div>
        <div class="card-item" v-if="document.персональні == 'так'">
          <p class="name">Персональні дані</p>
          <p class="disable">Документ містить персональні дані</p>
        </div>
      </div>
      <div class="card-block">
        <div class="card-item" v-if="document.дата">
          <p class="name">Дата</p>
          <p>{{ document.дата }}</p>
        </div>
        <div class="card-item" v-if="document.опубліковано">
          <p class="name">Дата публікації</p>
          <p>{{ document.опубліковано }}</p>
        </div>
        <div class="card-item" v-if="document.прийнято">
          <p class="name">Дата прийняття</p>
          <p>{{ document.прийнято }}</p>
        </div>
      </div>
    </div>

    <div class="section" v-if="document.персональні != 'так'">
      <div class=" card-item ">
        <p class="name">Документ</p>
        <div class="card-item-center" v-if="category == 7">
          <a
            :href="
              getLink(
                category,
                document.номер,
                document.дата || document.прийнято || document.опубліковано,
                document.id
              )[0]
            "
            target="_blank"
            class="button"
            download
            >Реєстрація</a
          ><a
            :href="
              getLink(
                category,
                document.номер,
                document.дата || document.прийнято || document.опубліковано,
                document.id
              )[1]
            "
            target="_blank"
            class="button"
            download
            >Голосування</a
          ><a
            :href="
              getLink(
                category,
                document.номер,
                document.дата || document.прийнято || document.опубліковано,
                document.id
              )[2]
            "
            target="_blank"
            class="button"
            download
            >Протокол засідання</a
          ><a
            :href="
              getLink(
                category,
                document.номер,
                document.дата || document.прийнято || document.опубліковано,
                document.id
              )[3]
            "
            target="_blank"
            class="button"
            download
            >Погоджувальна рада</a
          >
        </div>
        <div class="card-item-center" v-else>
          <a
            :href="
              getLink(
                category,
                document.номер,
                document.дата || document.прийнято || document.опубліковано,
                document.id
              )
            "
            target="_blank"
            class="button"
            download
            >Завантажити</a
          >
        </div>
      </div>
      <div class="card-item card-preview" v-if="category != 7">
        <p class="name">Перегляд документу</p>

        <iframe
          :src="
            `${getLink(
              category,
              document.номер,
              document.дата || document.прийнято || document.опубліковано,
              document.id
            )}`
          "
          frameborder="0"
          type="application/pdf"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
  import { outLink } from "../../components/functions/link";

  export default {
    name: "Document",

    props: ["document", "category"],

    setup() {
      const getLink = (c, n, d, i) => outLink(c, n, d, i);

      return { getLink };
    },
  };
</script>
<style lang="scss">
  .document-card {
    .general {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      grid-gap: 1em;
      border: 1px solid $acs;
      background: rgba($color: $acs, $alpha: 0.05);
    }

    .card-item {
      margin-bottom: 1em;

      &.card-preview {
        display: block;

        @media screen and (max-width: 721px) {
          display: none;
        }
      }

      .card-item-center {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: center;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        grid-gap: 1em;
        align-items: center;

        a {
          display: grid;
          place-items: center;
          text-align: center;
        }
      }

      .name {
        color: $mid-grey;
        font-size: 0.8em;
      }

      .disable {
        color: $red;
      }

      iframe {
        position: relative;
        display: block;
        width: 100%;
        height: 80vh;
        background: #fff;
        margin-top: 1em;
      }
    }
  }
</style>
