<template>
  <div class="document section">
    <div class="section-header">
      <div class="section-header--bread">
        <router-link to="/">Головна</router-link>
        <p>|</p>
        <router-link :to="`/documents?category=${category.url}`">{{ category.title }}</router-link>
      </div>
      <h3 v-if="category.url == 12">{{ document.підрозділ }}</h3>
      <h3 v-else>{{ document.назва ? document.назва : number[0] }}</h3>
    </div>

    <Loader v-if="load" />

    <Card v-else :document="document" :category="category.url" />
  </div>
</template>

<script>
  import { useRoute } from "vue-router";

  import axios from "axios";

  import Loader from "../components/global/Loader";

  import dates from "../store/lists/dates";
  import menu from "../store/lists/menu";

  import { useStore } from "vuex";

  import { ref, computed, onBeforeMount } from "vue";
  import Card from "../components/documents/Card.vue";

  export default {
    name: "Document",

    components: { Loader, Card },

    setup() {
      const store = useStore();
      const route = useRoute();
      const category = computed(() => menu.name.find((e) => e.url.toString() === route.query.category));
      const number = computed(() => route.query.number.split("-"));
      const load = ref(true);

      onBeforeMount(() => fetchDocument());

      //Fetch document

      const document = ref({});

      const fetchDocument = async () => {
        try {
          let get = await axios.get(dates.all[category.value.url]),
            header = get.data.values[0],
            rows = get.data.values.slice(1),
            formated = [];

          for (const row of rows) {
            const formattedRow = {};

            header.forEach((e, i) => {
              formattedRow[e.toLowerCase()] = row[i];
            });

            formated.push(formattedRow);
          }

          if (number.value.length == 2) {
            if (category.value.url === 3) {
              document.value = formated.find(
                (e) => e.номер === number.value[0] && e.прийнято.slice(-2) === number.value[1]
              );
            } else if (category.value.url == 6) {
              document.value = formated.find(
                (e) => e.номер === number.value[0] && e.опубліковано.slice(-2) === number.value[1]
              );
            } else if (category.value.url == 9) {
              document.value = formated.find((e) => e.номер === number.value[0] && e.id === number.value[1]);
            } else {
              document.value = formated.find(
                (e) => e.номер === number.value[0] && e.дата.slice(-2) === number.value[1]
              );
            }
          } else {
            document.value = formated.find((e) => e.номер === number.value[0]);
          }

          load.value = false;
        } catch (e) {
          store.commit("setModal", "error");
          console.log(e);
          load.value = false;
        }
      };

      return { category, number, load, document };
    },
  };
</script>
